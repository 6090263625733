<template>
  <div>
    <div
      class="card-body pt-2 pb-2"
      v-if="$parent.subasta && $parent.subasta.estado == 1"
    >
      <div class="row">
        <div class="col-md-12 text-right">
          <button
            type="button"
            @click="getDataModalProducto()"
            class="btn btn-primary btn-sm"
            data-toggle="modal"
            data-target="#Modal_productos"
            v-if="$store.getters.can('com.subastas.crearProducto')"
          >
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
    <div v-if="productos" class="card-body table-responsive p-0">
      <table class="table table-bordered table-striped table-hover table-sm">
        <thead class="bg-dark">
          <tr>
            <th>#</th>
            <th>Sitio</th>
            <th>Producto</th>
            <th>API</th>
            <th>Azufre</th>
            <th>Volumen</th>
            <th>Cant. Vehíc.</th>
            <th>Fecha Inicio</th>
            <th>Fecha Fin</th>
            <th>Precio Piso</th>
            <th>Moneda</th>
            <th
              class="col-1"
              v-if="$parent.subasta && $parent.subasta.estado == 1"
            >
              Acción
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in productos" :key="item.id">
            <td class="text-center">{{ item.id }}</td>
            <td>{{ item.sitio ? item.sitio.nombre : "Sin asignar" }}</td>
            <td>{{ item.producto ? item.producto.nombre : "Sin asignar" }}</td>
            <td class="text-right">{{ item.api }}</td>
            <td class="text-right">{{ item.s }}</td>
            <td class="text-right">{{ item.volumen }}</td>
            <td class="text-center">{{ item.cant_vh }}</td>
            <td class="text-center">{{ item.fecha_ini }}</td>
            <td class="text-center">{{ item.fecha_fin }}</td>
            <td v-if="item.precio_piso != null && item.signo != null">
              Brent
              <b>{{
                item.precio_piso != 0
                  ? item.nSigno + " " + item.precio_piso + " " + item.nMoneda
                  : item.nSigno + " " + "Oferta"
              }}</b>
            </td>
            <td class="text-center" v-else>
              <span class="badge bg-warning">
                Sin Asignar
              </span>
            </td>
            <td class="text-center">
              <span class="badge bg-info">
                {{ item.nMoneda }}
              </span>
            </td>
            <td
              class="text-right"
              v-if="$parent.subasta && $parent.subasta.estado == 1"
            >
              <div class="btn-group float-right">
                <button
                  type="button"
                  @click="getDataModalProducto(item)"
                  class="btn bg-navy btn-sm"
                  data-toggle="modal"
                  data-target="#Modal_productos"
                  v-if="$store.getters.can('com.subastas.editarProducto')"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  v-if="$store.getters.can('com.subastas.eliminarProducto')"
                  @click="destroy(item.id)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="card-body">
      <div class="row justify-content-center">
        <div class="callout callout-warning text-muted m-0">
          <h5 class="m-0">No hay productos asignados!</h5>
        </div>
      </div>
    </div>

    <div class="modal fade" id="Modal_productos">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalProductos"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="form-group col-md-6">
                <label>Sitio</label>
                <v-select
                  class="form-control form-control-sm p-0"
                  :class="[
                    $v.form.sitio.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.sitio"
                  placeholder="Sitio"
                  label="nombre"
                  :options="listasForms.sitios"
                  :filterable="false"
                  @search="buscarSitios"
                  @input="getProductos(true)"
                ></v-select>
              </div>
              <div class="form-group col-md-6">
                <label>Producto</label>
                <v-select
                  class="form-control form-control-sm p-0"
                  :class="[
                    $v.form.producto.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.producto"
                  placeholder="Producto"
                  label="nombre"
                  :options="listasForms.productos"
                  :filterable="false"
                  :disabled="!listasForms.productos.length > 0 || !form.sitio"
                  @input="getDetProducto"
                ></v-select>
                <small
                  class="text-danger"
                  v-if="form.sitio && listasForms.productos.length == 0"
                >
                  <strong
                    >No hay productos asociados al sitio
                    {{ form.sitio.nombre }}.</strong
                  >
                </small>
              </div>
              <div class="form-group col-md-3">
                <label>API</label>
                <input
                  type="number"
                  v-model="form.api"
                  class="form-control form-control-sm"
                  :class="$v.form.api.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Azufre</label>
                <input
                  type="number"
                  v-model="form.s"
                  class="form-control form-control-sm"
                  :class="$v.form.s.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Volumen (BD)</label>
                <input
                  type="number"
                  v-model="form.volumen"
                  class="form-control form-control-sm"
                  :class="$v.form.volumen.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Cantidad Vehículos</label>
                <input
                  type="number"
                  v-model="form.cant_vh"
                  class="form-control form-control-sm"
                  :class="$v.form.cant_vh.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Fecha Inicial</label>
                <input
                  type="date"
                  v-model="form.fecha_ini"
                  class="form-control form-control-sm"
                  :class="
                    $v.form.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validaFechas"
                />
              </div>
              <div class="form-group col-md-2">
                <label>Fecha Fin</label>
                <input
                  type="date"
                  v-model="form.fecha_fin"
                  class="form-control form-control-sm"
                  :class="
                    $v.form.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validaFechas"
                />
              </div>

              <div class="form-group col-md-4">
                <label>Precio Piso</label>

                <div class="input-group">
                  <b class="p-1">Brent </b>
                  <div class="custom-control custom-radio m-1">
                    <input
                      type="radio"
                      v-model="form.signo"
                      class="custom-control-input"
                      id="brentRadioPlus"
                      name="signo"
                      value="+"
                      :class="[
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                    />
                    <label class="custom-control-label" for="brentRadioPlus"
                      >+</label
                    >
                  </div>
                  <div class="custom-control custom-radio m-1">
                    <input
                      type="radio"
                      v-model="form.signo"
                      class="custom-control-input"
                      id="brentRadioMinus"
                      name="signo"
                      value="-"
                      :class="[
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                    />
                    <label class="custom-control-label" for="brentRadioMinus"
                      >-</label
                    >
                  </div>
                  <div class="custom-control custom-radio m-1">
                    <input
                      type="radio"
                      v-model="form.signo"
                      class="custom-control-input"
                      id="brentRadioFlat"
                      name="signo"
                      value="flat"
                      :class="[
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                    />
                    <label class="custom-control-label" for="brentRadioFlat"
                      >Flat</label
                    >
                  </div>
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fas fa-dollar-sign"></i>
                    </span>
                  </div>
                  <input
                    type="number"
                    v-model="form.precio_piso"
                    class="form-control form-control-sm float-right"
                    :disabled="form.signo == 'flat'"
                  />
                </div>
              </div>
              <div class="form-group col-md-3">
                <label>Moneda</label>
                <select
                  v-model="form.moneda"
                  class="form-control form-control-sm"
                  :class="$v.form.moneda.$invalid ? 'is-invalid' : 'is-valid'"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="moneda in listasForms.monedas"
                    :key="moneda.numeracion"
                    :value="moneda.numeracion"
                  >
                    {{ moneda.descripcion }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div
            v-if="!$v.form.$invalid"
            class="modal-footer justify-content-between"
          >
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "SubastaProducto",
  components: {
    vSelect,
  },
  data() {
    return {
      productos: null,
      modal: {},
      form: {},
      listasForms: {
        sitios: [],
        productos: [],
        monedas: [],
      },
    };
  },

  validations: {
    form: {
      sitio: {
        required,
      },
      producto: {
        required,
      },
      api: {
        required,
      },
      s: {
        required,
      },
      volumen: {
        required,
      },
      cant_vh: {
        required,
      },
      fecha_ini: {
        required,
      },
      fecha_fin: {
        required,
      },
      /* signo: {
        required,
      }, */
      /* precio_piso: {
        required,
      }, */
      moneda: {
        required,
      },
    },
  },

  methods: {
    getIndex() {
      this.productos =
        this.$parent.subasta &&
        this.$parent.subasta.productos &&
        this.$parent.subasta.productos.length > 0
          ? this.$parent.subasta.productos
          : null;
    },

    getMonedas() {
      axios.get("/api/lista/134").then((response) => {
        this.listasForms.monedas = response.data;
      });
    },

    getDataModalProducto(producto = null) {
      this.modal = {};
      this.modal.title = producto
        ? `Editar Producto - ${producto.producto.nombre}`
        : `Crear Producto | ${this.$parent.form.fecha_ini} - ${this.$parent.form.fecha_fin}`;
      this.resetForm();
      this.listasForms.sitios = [];
      this.listasForms.productos = [];
      this.form.fecha_ini = this.$parent.form.fecha_ini;
      this.form.fecha_fin = this.$parent.form.fecha_fin;
      if (producto) {
        this.form = {
          id: producto.id,
          sitio: producto.sitio ? producto.sitio : null,
          producto: producto.producto ? producto.producto : null,
          api: producto.api,
          s: producto.s,
          volumen: producto.volumen,
          cant_vh: producto.cant_vh,
          fecha_ini: producto.fecha_ini,
          fecha_fin: producto.fecha_fin,
          precio_piso: producto.precio_piso,
          moneda: producto.moneda,
          signo: producto.nSigno,
        };
        this.getProductos();
      }
    },

    // Acciones del formulario
    resetForm() {
      this.form = {
        id: null,
        sitio: null,
        producto: null,
        api: null,
        s: null,
        volumen: null,
        cant_vh: null,
        fecha_ini: null,
        fecha_fin: null,
        precio_piso: null,
        precio_oferta: null,
        moneda: null,
        signo: null,
      };
    },

    buscarSitios(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url, {
            // params: { in_linea_negocio: [11] } // Se quita el filtro de la línea de negocio por que no hay datos en la tabla de relación
          })
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.sitios = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getProductos(flag_form = false) {
      this.listasForms.productos = [];
      if (flag_form) {
        this.form.producto = null;
      }

      if (this.form.sitio) {
        let me = this;
        var url = "/api/com/subastas/producto/lista";
        axios
          .get(url, {
            params: { sitio_id: this.form.sitio.id },
          })
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.productos = respuesta;
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getDetProducto() {
      if (this.form.producto) {
        this.form.api = this.form.producto.api;
        this.form.s = this.form.producto.azufre;
      }
    },

    // Funciones de validaciones
    validaFechas() {
      let msg_error = "";
      // Se valida si la fecha inicial esta dentro del rango de las fechas de la subasta
      if (this.form.fecha_ini || this.form.fecha_fin) {
        let fecha_ini_subasta = new Date(this.$parent.subasta.fecha_ini);
        let fecha_fin_subasta = new Date(this.$parent.subasta.fecha_fin);
        if (this.form.fecha_ini) {
          let fecha_ini = new Date(this.form.fecha_ini);
          if (fecha_ini < fecha_ini_subasta || fecha_ini > fecha_fin_subasta) {
            this.form.fecha_ini = null;
            msg_error += `<div><strong>La fecha inicial está fuera del rango de tiempo de la subasta...</strong></div>`;
          }
        }

        if (this.form.fecha_fin) {
          let fecha_fin = new Date(this.form.fecha_fin);
          if (fecha_fin < fecha_ini_subasta || fecha_fin > fecha_fin_subasta) {
            this.form.fecha_fin = null;
            msg_error += `<div><strong>La fecha final está fuera del rango de tiempo de la subasta...</strong></div>`;
          }
        }
      }

      // Se valida le fecha inicial y final
      if (this.form.fecha_ini && this.form.fecha_fin) {
        let fecha_menor = new Date(this.form.fecha_ini);
        let fecha_mayor = new Date(this.form.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          msg_error += `<div><strong>La fecha inicial no puede ser mayor a la fecha final...</strong></div>`;
        }
      }
      // Si hay error se muestra
      if (msg_error) {
        this.$swal({
          icon: "error",
          html: msg_error,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    // Acciones de los botones
    save() {
      if (!this.$v.form.$invalid) {
        //this.$parent.cargando = true;

        // Se completa el formulario
        this.form.com_subasta_id = this.$parent.id;
        this.form.sitio_id = this.form.sitio ? this.form.sitio.id : null;
        this.form.producto_liquido_id = this.form.producto
          ? this.form.producto.id
          : null;
        this.form.sitio_id = this.form.sitio ? this.form.sitio.id : null;

        axios({
          method: this.form.id ? "PUT" : "POST",
          url: "/api/com/subastas/producto",
          data: this.form,
        })
          .then(() => {
            this.$parent.cargando = false;
            this.$refs.closeModalProductos.click();
            this.$parent.getIndex();

            this.$swal({
              icon: "success",
              title: "El producto se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((error) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(id) {
      this.$swal({
        title: "Está seguro de eliminar este Producto?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.$parent.cargando = true;
          let me = this;
          axios
            .delete("/api/com/subastas/producto/" + id)
            .then(() => {
              this.$parent.getIndex();
              this.$swal({
                icon: "success",
                title: "Se eliminó el Producto exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              me.$swal({
                icon: "error",
                title: "Ocurrió un error:" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              me.cargando = false;
            });
        }
      });
    },
  },

  beforeMount() {
    this.getMonedas();
  },
};
</script>
